import RequestSideMenu from "../Component/RequestComponent/RequestSideMenu";
import MainHeader from "../Component/Header";
import {
  Layout,
  theme,
  Upload,
  Button,
  Image,
  List,
  Space,
  Radio,
  Flex,
  Input,
  Spin,
  Col,
  Row,
  message,
  Statistic,
  Tag,
} from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  SendOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import dayjs from "dayjs";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import VirtualList from "rc-virtual-list";
import { useGlobalContext } from "../Component/GlobalContext";
import { gql, useMutation } from "@apollo/client";
import { uploadData, getUrl, remove } from "aws-amplify/storage";
import withThemeSwitch from "../Component/withThemeSwitch";

function getStatusColor(status) {
  switch (status) {
    case "New Updated":
      return "green";
    case "On Progress":
      return "cyan";
    case "Finished":
      return "red";
    case "Pending":
      return "orange";
    case "Paid":
      return "blue";
    case "Creating":
      return "purple";
    default:
      return "grey";
  }
}

const ADD_COMMENT = gql`
  mutation AddComment($requestUid: uuid!, $content: String!, $image: String) {
    insert_request_comment_one(
      object: { request_id: $requestUid, content: $content, image: $image }
    ) {
      id
    }
  }
`;

function RequestDetails() {
  const [height, setHeight] = useState(window.innerHeight);

  const [inputMessage, setInputMessage] = useState("");
  const location = useLocation();
  const requestUid = location.state?.requestUid;
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const [requestDetails, setRequestDetails] = useState({});
  const [commentList, setCommentList] = useState([]);
  const [resolvedCommentList, setResolvedCommentList] = useState([]);
  const [uploadFile, setUploadFile] = useState([]);
  const requestId = useRef(0);

  const {
    RequestDetailData: data,
    fetchRequestDetail: fetchData,
    RequestDetailLoading: loading,
    fetchComments,
    CommentsData,
    CommentsLoading,
    handleRefetchComments,
    setIsCommentsRefetching,
  } = useContext(useGlobalContext);
  const [commentSelection, setCommentSelection] = useState("active");
  const [addComment] = useMutation(ADD_COMMENT);

  const onCommentMenuClick = (e) => {
    console.log(e.target.value);
    setCommentSelection(e.target.value);
  };
  //This function is used to format the file name in order to prevent the same file name in AWS S3
  const formatFileName = (file) => {
    const fileNameParts = file.name.split(".");
    const extension = fileNameParts.pop();
    const baseName = fileNameParts.join(".");
    const timestamp = Date.now();
    const newFileName = `${baseName}-${timestamp}.${extension}`;
    return newFileName;
  };
  //This function is used to delete the uploaded file when the user click the remove button
  function deleteTempRequest() {
    if (uploadFile.length > 0) {
      try {
        console.log("Remove ", uploadFile);
        remove({
          path: uploadFile[0].path,
        });
      } catch (error) {
        console.log("Error ", error);
      }
      setUploadFile([]);
    }
  }
  //This function is used to add the comment to the database
  function handleAddComment() {
    setIsCommentsRefetching(true);
    if (inputMessage !== "") {
      addComment({
        variables: {
          requestUid: requestUid,
          content: inputMessage,
          image: uploadFile.length > 0 ? uploadFile[0].path : "",
        },
      })
        .then(() => {
          setInputMessage("");
          setUploadFile([]);
          handleRefetchComments({ id: requestUid });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  //This is used for Ant Design Upload component to display the file uploading progress and status
  const attachmentProps = {
    customRequest: async ({
      file,
      onSuccess,
      onError,
      onProgress: onProgressInternal,
    }) => {
      try {
        const formatedFileName = formatFileName(file);
        await uploadData({
          path: `public/${requestUid}/commentFile/${formatedFileName}`,
          data: file,
          options: {
            onProgress: async (progressEvent) => {
              const percent = Math.round(
                (progressEvent.transferredBytes / progressEvent.totalBytes) *
                  100
              );
              onProgressInternal({ percent }, file);
              if (percent === 100) {
                file.path = `public/${requestUid}/commentFile/${formatedFileName}`;
              }
            },
          },
        }).result;

        onSuccess(file);
      } catch (error) {
        file.status = "error";
        onError(error, file);
      }
    },

    accept: ".jpg,.jpeg,.png",
    maxCount: 1,
    onChange(info) {
      const { status, name } = info.file;
      if (status === "uploading") {
        info.file.name = formatFileName(info.file);
        setUploadFile([info.file]);

        if (status === "error") {
          message.error(`${name} file upload failed.`);
        }
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      style: {
        maxWidth: "20vw",
        fontSize: 12,
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onSuccess: (file) => {
      message.success(`${file.name} file uploaded successfully.`);
      setUploadFile([file]);
    },
    onError: (error, file) => {
      message.error(`${file.name} upload failed. Because ${error}`);
      file.status = "error";
      setUploadFile([file]);
    },
    onRemove: (info) => {
      try {
        console.log("Remove ", uploadFile);
        remove({
          path: uploadFile[0].path,
        });
      } catch (error) {
        console.log("Error ", error);
      }
      setUploadFile([]);
    },
  };

  //Convert the fetching data to the displayed table data
  useEffect(() => {
    requestId.current += 1;
    if (CommentsData) {
      const activeComments = [];
      const resolvedComments = [];
      const currentRequestId = requestId.current;
      const promises = CommentsData.request_comment.map(async (element) => {
        let pictureUrl = null;
        if (element.image) {
          pictureUrl = await getUrl({
            path: element.image,
            options: {
              validateObjectExistence: true,
            },
          });
        }
        const comment = {
          uid: element.id,
          content: element.content,
          picture: pictureUrl ? pictureUrl.url.href : "",
          publishDate: dayjs(element.published_date).format("DD/MM/YYYY"),
          orderDate: element.published_date,
          state: element.status,
          resolvedDate: element.updated_date
            ? dayjs(element.updated_date).format("DD/MM/YYYY")
            : "-",
        };

        if (element.status === "active") {
          activeComments.push(comment);
        } else if (element.status === "resolved") {
          resolvedComments.push(comment);
        }
      });

      Promise.all(promises).then(() => {
        if (currentRequestId === requestId.current) {
          activeComments.sort(
            (a, b) => new Date(b.orderDate) - new Date(a.orderDate)
          );
          resolvedComments.sort(
            (a, b) => new Date(b.orderDate) - new Date(a.orderDate)
          );

          setCommentList(activeComments);
          setResolvedCommentList(resolvedComments);
        }
      });
    } else {
      setCommentList([]);
      setResolvedCommentList([]);
    }
  }, [CommentsData]);

  //This useEffect is used to convert the fetched data to the displayed data

  useEffect(() => {
    if (data) {
      var detail = {};
      if (data.request_by_pk) {
        const dueDate = new Date(
          data.request_by_pk.due_date
        ).toLocaleDateString("en-NZ");
        detail = {
          id: data.request_by_pk.id,
          actualPrice: data.request_by_pk.actual_price,
          dueDate: data.request_by_pk.due_date ? dueDate : "-",
          estimatePrice: data.request_by_pk.estimate_price
            ? data.request_by_pk.estimate_price
            : "TBD",
          status: data.request_by_pk.status,
          updateDate: data.request_by_pk.update_date,
          requestName: data.request_by_pk.title,
          requestDescription: data.request_by_pk.description,
          workStreams: data.request_by_pk.work_streams,
          constructionMethodology: data.request_by_pk.construction_methodology,
          methodologyBuilding: data.request_by_pk.methodology_building,
          methodologyPhase: data.request_by_pk.methodology_phase,
          methodologyOutputs: data.request_by_pk.methodology_outputs,
          modelDevelopment: data.request_by_pk.model_development,
          modelPhase: data.request_by_pk.model_phase,
          modelEquipment: data.request_by_pk.model_equipment,
          modelType: data.request_by_pk.model_type,
          modelUsage: data.request_by_pk.model_usage,
          downloadLink: data.request_by_pk.download_link
            ? data.request_by_pk.download_link
            : "-",

          fileDescription: [],
        };
      } else {
        detail = {
          id: data.id,
          actualPrice: data.actual_price,
          dueDate: data.due_date ? data.due_date : "-",
          estimatePrice: data.estimate_price ? data.estimate_price : "TBD",
          status: data.status,
          updateDate: data.update_date,
          requestName: data.title,
          requestDescription: data.description,
          workStreams: data.work_streams,
          constructionMethodology: data.construction_methodology,
          methodologyBuilding: data.methodology_building,
          methodologyPhase: data.methodology_phase,
          methodologyOutputs: data.methodology_outputs,
          modelDevelopment: data.model_development,
          modelPhase: data.model_phase,
          modelEquipment: data.model_equipment,
          modelType: data.model_type,
          modelUsage: data.model_usage,
          downloadLink: data.download_link,
          fileDescription: [],
        };
      }
      setRequestDetails(detail);
    }
  }, [data]);

  //This useEffect is used to prevent user directly access the page without login

  useEffect(() => {
    if (isMounted) {
      if (requestUid === undefined) {
        navigate("/request_list");
      } else {
        fetchData({
          variables: { requestUid: requestUid },
        });
        fetchComments({
          variables: { requestUid: requestUid },
        });
      }
    }
  }, [isMounted, navigate, requestUid, fetchData, fetchComments]);

  useEffect(() => {
    setIsMounted(true);
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const {
    token: { colorBgContainer, colorBorder, colorBgLayout },
  } = theme.useToken();
  // Used to delete uploaded images when close the page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      deleteTempRequest();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  });
  // Used to delete uploaded images when leaving the page

  return (
    <MainHeader select="Request">
      <RequestSideMenu select="1" deleteCommentFile={deleteTempRequest}>
        {loading && (
          <div
            style={{
              width: "100%",
              height: height - 66,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: colorBgContainer,
            }}
          >
            <Spin tip="Loading..." size="large" />
          </div>
        )}
        {!loading && (
          <Layout.Content
            style={{
              marginLeft: 2,
              minHeight: 280,
              background: colorBgContainer,
              height: height - 66,
              overflowY: "hidden",
            }}
          >
            <div
              style={{
                width: "100%",
                height: 100,
                paddingTop: 0,
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Button
                type="text"
                icon={<ArrowLeftOutlined style={{ fontSize: 30 }} />}
                style={{ position: "absolute", left: "2vw" }}
                onClick={() => {
                  deleteTempRequest();
                  navigate(-1);
                }}
              />
              <h1 style={{ margin: " auto" }}>
                {requestDetails.requestName}
                <Button
                  type="text"
                  style={{ marginLeft: "3vw" }}
                  icon={<EditOutlined style={{ fontSize: 24 }} />}
                  onClick={() => {
                    deleteTempRequest();
                    navigate("/request_creation", {
                      state: {
                        requestDetails: requestDetails,
                      },
                    });
                  }}
                />
              </h1>
            </div>
            <div style={{ display: "flex", paddingTop: 20 }}>
              <div
                style={{
                  width: "30%",
                  paddingLeft: "2vw",
                  paddingRight: "2vw",
                  borderTopWidth: 1,
                  borderTopColor: colorBorder,
                  borderTopStyle: "solid",
                }}
              >
                <Row gutter={16} style={{ paddingTop: "2vh" }}>
                  <Col span={12}>
                    <Statistic
                      title="State"
                      prefix={
                        <Tag
                          color={getStatusColor(requestDetails.status)}
                          style={{ fontSize: 18, padding: "5px 10px" }}
                        >
                          {requestDetails.status}
                        </Tag>
                      }
                      value=" "
                      valueStyle={{
                        color: getStatusColor(requestDetails.status),
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Due Date"
                      value={requestDetails.dueDate}
                    />
                  </Col>
                  <Col span={12} style={{ paddingTop: "2vh" }}>
                    <Statistic
                      title="Actual price"
                      value={requestDetails.actualPrice}
                    />
                  </Col>
                  <Col span={12} style={{ paddingTop: "2vh" }}>
                    <Statistic
                      title="Estimate price"
                      value={requestDetails.estimatePrice}
                    />
                  </Col>
                </Row>

                <Statistic
                  title="Download Output Files"
                  prefix={
                    requestDetails.downloadLink === "-" ? null : (
                      <div style={{ width: "100%" }}>
                        <a
                          href={requestDetails.downloadLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "block",
                            width: "22vw",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {requestDetails.downloadLink}
                        </a>
                      </div>
                    )
                  }
                  value={requestDetails.downloadLink === "-" ? "-" : " "}
                  valueStyle={{ fontSize: 16 }}
                  style={{ paddingTop: "2vh" }}
                />

                <Statistic
                  title="Description"
                  value={requestDetails.requestDescription}
                  valueStyle={{ fontSize: 16 }}
                  style={{ paddingTop: "2vh" }}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "70%",
                  height: height - 186,
                  borderWidth: 1,
                  borderColor: colorBorder,
                  borderStyle: "solid",
                }}
              >
                <Spin spinning={CommentsLoading}>
                  <List>
                    <div>
                      <div
                        style={{
                          height: 120,
                          borderBottomWidth: 1,
                          borderBottomColor: colorBorder,
                          borderBottomStyle: "solid",
                        }}
                      >
                        <div
                          style={{
                            height: 80,
                            boxShadow: "none", // Remove the shadow
                            backgroundColor: "transparent",
                            fontWeight: "bold",
                            fontSize: 20,
                            alignContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Comments
                        </div>
                        <StyledRadioGroup
                          value={commentSelection}
                          onChange={onCommentMenuClick}
                          style={{
                            width: "100%",

                            zIndex: 2,
                            position: "relative",
                            marginBottom: -8,
                            fontWeight: "bold",
                            fontSize: 14,
                            borderWidth: 0,
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Radio.Button
                            value="active"
                            style={{
                              width: "50%",
                              height: "100%",
                              alignContent: "center",
                              borderLeftWidth: 0,
                              borderTopWidth: 0,
                              borderBottomWidth: 0,
                              borderRadius: 0,
                              borderRightWidth: 0,
                              textAlign: "center",
                              backgroundColor: "transparent",
                            }}
                          >
                            Active
                          </Radio.Button>
                          <Radio.Button
                            value="resolved"
                            style={{
                              width: "50%",
                              height: "100%",
                              alignContent: "center",
                              borderBottomWidth: 0,
                              borderRightWidth: 0,
                              borderTopWidth: 0,
                              borderRadius: 0,
                              borderLeftWidth: 0,
                              borderLeftColor: "transparent",
                              borderLeftStyle: "none",
                              textAlign: "center",
                              backgroundColor: "transparent",
                            }}
                          >
                            Resolved
                          </Radio.Button>
                        </StyledRadioGroup>
                      </div>
                      <VirtualList
                        data={
                          commentSelection === "active"
                            ? commentList
                            : resolvedCommentList
                        }
                        height={height - 424}
                        itemKey="uid"
                        style={{
                          borderTopWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: colorBorder,
                          borderStyle: "solid",
                          borderLeftWidth: 0,
                          borderRightWidth: 0,

                          zIndex: 1,
                        }}
                      >
                        {(item, index) => {
                          let borderTop = false;
                          if (index === 0) {
                            borderTop = true;
                          }
                          let imageDisplay = "none";
                          if (item.picture !== "") {
                            imageDisplay = "";
                          }
                          return (
                            <List.Item
                              key={item.uid}
                              style={{
                                fontSize: 16,
                                padding: 16,
                                borderTopWidth: borderTop ? 0 : 1,
                                borderStyle: "solid",
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                borderRightWidth: 0,
                                marginTop: 8,
                                borderColor: colorBorder,
                                alignContent: "center",
                              }}
                            >
                              <Space direction="vertical">
                                <div>{item.content}</div>
                                <Image
                                  width={150}
                                  src={item.picture}
                                  style={{ display: imageDisplay }}
                                ></Image>
                                <div style={{ fontSize: 12 }}>
                                  Published Date: {item.publishDate}
                                </div>
                                {item.resolvedDate !== "-" && (
                                  <div style={{ fontSize: 12 }}>
                                    Resolved Date: {item.resolvedDate}
                                  </div>
                                )}
                              </Space>
                            </List.Item>
                          );
                        }}
                      </VirtualList>
                      <div
                        style={{
                          height: 118,
                          alignContent: "center",
                          paddingLeft: 24,
                          borderLeftRadius: 10,
                          borderRightRadius: 10,
                          backgroundColor: colorBgLayout,
                        }}
                      >
                        <Flex direction="horizontal" style={{ width: "100%" }}>
                          <Input.TextArea
                            placeholder="Type your message"
                            autoSize={{ minRows: 3, maxRows: 3 }}
                            style={{ width: "95%" }}
                            onChange={(e) => setInputMessage(e.target.value)}
                            value={inputMessage}
                          />
                          <Space
                            direction="vertical"
                            size={"large"}
                            style={{
                              paddingLeft: 16,
                              paddingRight: 16,
                              maxWidth: "20vw",
                            }}
                          >
                            <Upload {...attachmentProps} fileList={uploadFile}>
                              <LinkOutlined
                                style={{
                                  fontSize: 16,
                                  paddingTop: 16,
                                }}
                              />
                            </Upload>

                            {inputMessage !== "" ? (
                              <SendOutlined
                                style={{ fontSize: 16, paddingBottom: 8 }}
                                onClick={(e) => {
                                  handleAddComment();
                                }}
                              />
                            ) : null}
                          </Space>
                        </Flex>
                      </div>
                    </div>
                  </List>
                </Spin>
              </div>
            </div>
          </Layout.Content>
        )}
      </RequestSideMenu>
    </MainHeader>
  );
}

export default withThemeSwitch(RequestDetails);

const StyledRadioGroup = styled(Radio.Group)`
  height: 40 !important;

  .ant-radio-button-wrapper:not(:first-child)::before {
    content: none; // This line removes the separator
  }
`;
