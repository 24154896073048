import React, { createContext, useState } from "react";
import { useLazyQuery, gql } from "@apollo/client";

export const useGlobalContext = createContext();

const GET_REQUEST_DETAILS = gql`
  query GetDetails($requestUid: uuid!) {
    request_by_pk(id: $requestUid) {
      id
      actual_price
      construction_methodology
      description
      due_date
      estimate_price
      methodology_building
      methodology_outputs
      model_development
      methodology_phase
      model_equipment
      model_phase
      model_type
      model_usage
      status
      title
      update_date
      work_streams
      download_link
    }
  }
`;
const GET_REQUEST_LIST = gql`
  query Query($user_id: uuid!) {
    get_request_list_by_user_id(args: { p_user_id: $user_id }) {
      update_date
      title
      technician_name
      status
      id
      due_date
    }
  }
`;
const GET_COMMENTS = gql`
  query GetComments($requestUid: uuid!) {
    request_comment(where: { request_id: { _eq: $requestUid } }) {
      id
      content
      published_date
      updated_date
      status
      image
    }
  }
`;

export default function GlobalContext(props) {
  const [isListRefetching, setIsListRefetching] = useState(false);
  const [isDetailRefetching, setIsDetailRefetching] = useState(false);
  const [isCommentsRefetching, setIsCommentsRefetching] = useState(false);

  const [
    fetchRequestDetail,
    {
      loading: RequestDetailLoading,
      error,
      data: RequestDetailData,
      refetch: refetchRequestDetail,
    },
  ] = useLazyQuery(GET_REQUEST_DETAILS);

  if (error) {
    console.log(error.message);
  }

  const [
    fetchRequestList,
    {
      loading: RequestListLoading,
      error: RequestListError,
      data: RequestListData,
      refetch: refetchRequestList,
    },
  ] = useLazyQuery(GET_REQUEST_LIST);
  if (RequestListError) {
    console.log(RequestListError.message);
  }

  const [
    fetchComments,
    {
      loading: CommentsLoading,
      error: CommentsError,
      data: CommentsData,
      refetch: refetchComments,
    },
  ] = useLazyQuery(GET_COMMENTS);
  if (CommentsError) {
    console.log(CommentsError.message);
  }

  const handleRefetchRequestDetail = async () => {
    await refetchRequestDetail();
    setIsDetailRefetching(false);
  };

  const handleRefetchRequestList = async () => {
    await refetchRequestList();
    setIsListRefetching(false);
  };

  const handleRefetchComments = async () => {
    await refetchComments();
    setIsCommentsRefetching(false);
  };

  return (
    <useGlobalContext.Provider
      value={{
        fetchRequestDetail,
        RequestDetailLoading: RequestDetailLoading || isDetailRefetching,
        RequestDetailData,
        handleRefetchRequestDetail,
        setIsDetailRefetching,
        fetchRequestList,
        RequestListLoading: RequestListLoading || isListRefetching,
        RequestListData,
        handleRefetchRequestList,
        setIsListRefetching,
        fetchComments,
        CommentsLoading: CommentsLoading || isCommentsRefetching,
        CommentsData,
        handleRefetchComments,
        setIsCommentsRefetching,
      }}
    >
      {props.children}
    </useGlobalContext.Provider>
  );
}
