export const members = [
  {
    name: "Bilel Ragued - Director, Virtual Construction Specialist",
    description: "Docotr in Civil Engineering",
    image: "/Members Photo/bilel-ragued.png",
  },
  {
    name: "Fadoua Jouili - Office Administrator",
    description: "Master in Data Science",
    image: "/Members Photo/fadoua-jouili.png",
  },
  {
    name: "Aya Jouili - Virtual Construction Technician",
    description: "Bachelor in Interior Design",
    image: "/Members Photo/aya-jouili.png",
  },
  {
    name: "Jay Yang - Virtual Construction Engineer",
    description: "Master in Architecture",
    image: "/Members Photo/jay-yang.jpg",
  },

  {
    name: "Sam Xu - Developer",
    description: "Bachelor in Software Engineering (Hons.)",
    image: "/Members Photo/sam-xu.jpg",
  },
  {
    name: "Bilal Jawadi - Virtual Construction Engineer",
    description: "Bachelor in Construction Engineering (Hons.)",
    image: "/Members Photo/bilal-jawadi.jpg",
  },
];
