import RequestList from "./Request/RequestList";
import RequestMessage from "./Request/ReuestMessage";
import RequestCreation from "./Request/RequestCreation";
import RequestDetails from "./Request/RequestDetails";
import RequestCreationReducer from "./Request/RequestCreationState/RequestCreationReducer";
import Home from "./HomePage/Home";
import HomePortfolio from "./HomePage/HomePortfolio";
import HomeService from "./HomePage/HomeService";
import Member from "./HomePage/Member";
import UserDetails from "./Component/UserDetails";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="home_portfolio/:page" element={<HomePortfolio />} />
        <Route path="home_service/:page" element={<HomeService />} />
        <Route path="home_member" element={<Member />} />
        <Route path="request_list" element={<RequestList />} />
        <Route path="request_message" element={<RequestMessage />} />
        <Route
          path="request_creation"
          element={
            <RequestCreationReducer>
              <RequestCreation />
            </RequestCreationReducer>
          }
        />
        <Route path="request_details" element={<RequestDetails />} />
        <Route path="user_details" element={<UserDetails />} />
      </Routes>
    </BrowserRouter>
  );
}
