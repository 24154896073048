import styled, { keyframes, css } from "styled-components";
import {
  Button,
  Modal,
  ConfigProvider,
  theme,
  Input,
  Form,
  message,
} from "antd";
import { useState, useEffect, useRef } from "react";
import {
  LockOutlined,
  UserOutlined,
  LoginOutlined,
  MailOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import {
  signUp,
  confirmSignUp,
  signIn,
  signOut,
  autoSignIn,
  resetPassword,
  confirmResetPassword,
} from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";

const SIGNUP_REQUEST = gql`
  mutation insert_user_one($id: uuid!, $name: String!, $email: String!) {
    insert_user_one(object: { id: $id, name: $name, email: $email }) {
      id
    }
  }
`;

export default function SignIn(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [signUpDialog, setSignUpDialog] = useState(false);
  const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);
  const [authStep, setAuthStep] = useState(null);
  const navigate = useNavigate();
  const userEmail = useRef(null);
  const userName = useRef(null);
  const userIdRef = useRef(null);
  const [resendDisable, setResendDisable] = useState(true);
  const [resetEmail, setResetEmail] = useState("");
  const [resendCountdown, setResendCountdown] = useState(0);

  const [addUser] = useMutation(SIGNUP_REQUEST);

  const ismobile = props.ismobile;

  useEffect(() => {
    if (authStep === "DONE") {
      navigate("/request_list");
    }
    if (authStep === "COMPLETE_AUTO_SIGN_IN") {
      async function handleAutoSignIn() {
        const { isSignedIn } = await autoSignIn();
        if (isSignedIn) {
          navigate("/request_list");
        }
      }
      try {
        addUser({
          variables: {
            id: userIdRef.current,
            name: userName.current,
            email: userEmail.current,
          },
        });
      } catch (error) {
        message.error(
          "Failed to submit the request, please try again later. Error: " +
            error.message
        );
      }
      handleAutoSignIn();
    }
  }, [authStep, navigate, addUser]);

  useEffect(() => {
    let timer;
    if (resendCountdown > 0) {
      timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
    } else if (resetEmail && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(resetEmail)) {
      setResendDisable(false);
    }
    return () => clearTimeout(timer);
  }, [resendCountdown, resetEmail]);

  const handleResendClick = () => {
    handleResetPassword(resetEmail);
    setResendDisable(true);
    setResendCountdown(60);
  };

  async function handleSignOut() {
    try {
      await signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  async function handleSignUp({ name, password, email }) {
    try {
      const { nextStep, userId } = await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
          },
          // optional
          autoSignIn: true, // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
        },
      });

      userEmail.current = email;

      userName.current = name;
      userIdRef.current = userId;
      setAuthStep(nextStep.signUpStep);
    } catch (error) {
      if (error.message === "User already exists") {
        message.error(
          "User already exists, please sign in! If you forgot your password, please click 'Forgot password'!"
        );
      }
    }
  }

  async function handleSignUpConfirmation(values) {
    const confirmationCode = values.code;
    try {
      const { nextStep } = await confirmSignUp({
        username: userEmail.current,
        confirmationCode,
      });

      setAuthStep(nextStep.signUpStep);
    } catch (error) {
      console.log("error confirming sign up", error);
    }
  }

  async function handleSignIn({ username, password }) {
    try {
      const { nextStep } = await signIn({ username, password });
      setAuthStep(nextStep.signInStep);
    } catch (error) {
      if (error.message === "There is already a signed in user.") {
        navigate("/request_list");
      } else {
        message.error("Invalid username or password, please try again!");
      }
    }
  }

  async function handleResetPassword(username) {
    try {
      const output = await resetPassword({ username });
      const { nextStep } = output;
      if (nextStep.resetPasswordStep === "CONFIRM_RESET_PASSWORD_WITH_CODE") {
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(codeDeliveryDetails);
        message.success(
          `Confirmation code was sent to ${codeDeliveryDetails.destination}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleConfirmResetPassword({
    username,
    confirmationCode,
    newPassword,
  }) {
    let bug = false;
    try {
      await confirmResetPassword({
        username,
        confirmationCode,
        newPassword,
      });
    } catch (error) {
      message.error(error.message);
      console.log(error);
      bug = true;
    } finally {
      if (!bug) {
        message.success(
          "Password has been reset successfully! Please log in with your new password."
        );
        setForgotPasswordDialog(false);
      }
    }
  }

  const onFinish = (values) => {
    if (signUpDialog) {
      handleSignUp({
        name: values.name,
        password: values.password,
        email: values.email,
      });
    } else {
      handleSignIn({
        username: values.email,
        password: values.password,
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onResetFinish = (values) => {
    handleConfirmResetPassword({
      username: resetEmail,
      confirmationCode: values.verificationCode,
      newPassword: values.newPassword,
    });
  };
  return (
    <div style={props.style}>
      <LoginButton
        icon={ismobile ? <LoginOutlined /> : ""}
        ismobile={ismobile}
        onClick={(e) => {
          // handleSignOut();
          // setModalOpen(true);
          window.location.href = "mailto:bilel@rfsons.engineering";
        }}
      >
        {ismobile ? " " : "Get In Touch"}
        {/* {ismobile ? " " : "Sign In"} */}
      </LoginButton>
      <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
        <Modal
          title={
            <div style={{ fontSize: 30 }}>
              {signUpDialog ? "Sign Up" : "Sign In"}
            </div>
          }
          centered
          style={{ textAlign: "center" }}
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
          footer={[]}
        >
          <Form
            name="sign"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                { required: true, message: "Please input your email!" },
              ]}
            >
              <Input
                size="large"
                placeholder="Email"
                style={{ width: "80%", marginTop: "10%" }}
                prefix={<MailOutlined style={{ paddingRight: 12 }} />}
              />
            </Form.Item>
            <Form.Item
              name="name"
              style={{
                height: signUpDialog ? "auto" : 0,
                padding: signUpDialog ? "auto" : 0,
              }}
              rules={[
                {
                  required: signUpDialog ? true : false,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Name"
                style={{
                  height: signUpDialog ? "auto" : 0,
                  padding: signUpDialog ? 7 : 0,
                  paddingLeft: signUpDialog ? 11 : 0,
                  paddingRight: signUpDialog ? 11 : 0,
                  borderWidth: signUpDialog ? "auto" : 0,
                  width: "80%",
                }}
                prefix={
                  <UserOutlined
                    style={{
                      paddingRight: 12,
                      fontSize: signUpDialog ? 16 : 0,
                    }}
                  />
                }
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                signUpDialog
                  ? () => ({
                      validator(_, value) {
                        if (
                          (!value ||
                            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(
                              value
                            )) &&
                          value.length >= 8
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Password must contain at least 8 characters, including letters and numbers!"
                          )
                        );
                      },
                    })
                  : null,
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                placeholder="Password"
                size="large"
                style={{ width: "80%" }}
                prefix={<LockOutlined style={{ paddingRight: 12 }} />}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              style={{
                height: signUpDialog ? "auto" : 0,
                padding: signUpDialog ? "auto" : 0,
              }}
              rules={[
                {
                  required: signUpDialog ? true : false,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Confirm Password"
                style={{
                  height: signUpDialog ? "auto" : 0,
                  padding: signUpDialog ? 7 : 0,
                  paddingLeft: signUpDialog ? 11 : 0,
                  paddingRight: signUpDialog ? 11 : 0,
                  borderWidth: signUpDialog ? "auto" : 0,
                  width: "80%",
                }}
                prefix={
                  <LockOutlined
                    style={{
                      paddingRight: 12,
                      fontSize: signUpDialog ? 16 : 0,
                    }}
                  />
                }
                iconRender={(visible) =>
                  signUpDialog ? (
                    visible ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )
                  ) : null
                }
              />
            </Form.Item>
            <p>
              {signUpDialog ? (
                " "
              ) : (
                <Button
                  type="link"
                  onClick={() => {
                    setForgotPasswordDialog(true);
                  }}
                >
                  Forgot password
                </Button>
              )}
            </p>
            <p>
              {signUpDialog
                ? "Already have an account? "
                : "Haven't got an account? "}
              <Button
                type="link"
                onClick={() => {
                  signUpDialog ? setSignUpDialog(false) : setSignUpDialog(true);
                }}
              >
                {signUpDialog ? "Sign in" : "Sign up"}
              </Button>
            </p>
            <Form.Item style={{ textAlign: "center" }}>
              <Button key="submit" type="primary" htmlType="submit">
                {signUpDialog ? "Sign Up" : "Sign In"}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={
            <div style={{ fontSize: 24 }}>
              A Verfication Code has been sent to you Email.
            </div>
          }
          centered
          style={{ textAlign: "center" }}
          open={authStep === "CONFIRM_SIGN_UP" && modalOpen}
          onCancel={() => setModalOpen(false)}
          footer={[]}
        >
          <Form
            name="verification"
            initialValues={{ remember: true }}
            onFinish={handleSignUpConfirmation}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message:
                    "Please input your verification code! If you didn't receive it please check junk mail.",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Verification Code"
                style={{ width: "80%", marginTop: "10%" }}
                prefix={
                  <SafetyCertificateOutlined style={{ paddingRight: 12 }} />
                }
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button key="submit" type="primary" htmlType="submit">
                {signUpDialog ? "Sign Up" : "Sign In"}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={<div style={{ fontSize: 24 }}>Reset Password</div>}
          centered
          style={{ textAlign: "center" }}
          open={forgotPasswordDialog}
          onCancel={() => {
            setModalOpen(false);
            setForgotPasswordDialog(false);
          }}
          footer={[]}
        >
          <Form
            name="forgetPassword"
            initialValues={{ remember: true }}
            onFinish={onResetFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                { required: true, message: "Please input your email!" },
              ]}
            >
              <Input
                size="large"
                placeholder="Email"
                style={{ width: "80%", marginTop: "10%" }}
                prefix={<MailOutlined style={{ paddingRight: 12 }} />}
                onChange={(e) => {
                  setResetEmail(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              name="newPassword"
              rules={[
                signUpDialog
                  ? () => ({
                      validator(_, value) {
                        if (
                          (!value ||
                            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(
                              value
                            )) &&
                          value.length >= 8
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Password must contain at least 8 characters, including letters and numbers!"
                          )
                        );
                      },
                    })
                  : null,
                { required: true, message: "Please input your new password!" },
              ]}
            >
              <Input.Password
                placeholder="Password"
                size="large"
                style={{ width: "80%" }}
                prefix={<LockOutlined style={{ paddingRight: 12 }} />}
              />
            </Form.Item>
            <Form.Item
              name="verificationCode"
              style={{
                height: "auto",
                padding: "auto",
              }}
              rules={[
                {
                  required: true,
                  message: "Please input your verification code!",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Verification Code"
                style={{
                  height: "auto",
                  padding: 7,
                  paddingLeft: 11,
                  paddingRight: 11,
                  borderWidth: "auto",
                  width: "80%",
                }}
                prefix={
                  <UserOutlined
                    style={{
                      paddingRight: 12,
                      fontSize: 16,
                    }}
                  />
                }
              />
            </Form.Item>
            <p>
              <Button
                type="link"
                onClick={handleResendClick}
                disabled={resendDisable}
              >
                {resendCountdown > 0
                  ? `Please wait for ${resendCountdown} seconds to resend`
                  : "Send Verification Code"}
              </Button>
            </p>
            <Form.Item style={{ textAlign: "center" }}>
              <Button key="submit" type="primary" htmlType="submit">
                Confirm
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </ConfigProvider>
    </div>
  );
}

const ripple = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(2, 138, 138, 0.4);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(2, 138, 138, 0);
  }
`;

const LoginButton = styled(Button)`
  font-size: calc(
    100vh * ${(props) => (props.ismobile ? 20 : 18)} /
      ${(props) => (props.ismobile ? 720 : 1080)}
  );
  height: 60%;
  background-color: transparent;
  color: white;
  animation: ${(props) =>
    props.ismobile
      ? "none"
      : css`
          ${ripple} 1.5s infinite
        `};
  border: 1px solid white;
  border-width: ${(props) => (props.ismobile ? "0px" : "1px")};
  &&:hover {
    background-color: transparent !important;
    color: #18dcff !important;
  }
`;
