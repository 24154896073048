import { createContext, useReducer } from "react";

export const ButtonStateContext = createContext();

function reducer(state, action) {
  switch (action.type) {
    case "singleSelect":
      if (state[action.category] === action.value) {
        return {
          ...state,
          [action.category]: "None",
        };
      } else {
        return {
          ...state,
          [action.category]: action.value,
        };
      }

    case "multiSelect":
      if (state[action.category].includes(action.value)) {
        return {
          ...state,
          [action.category]: state[action.category].filter(
            (item) => item !== action.value
          ),
        };
      } else {
        return {
          ...state,
          [action.category]: state[action.category].concat(action.value),
        };
      }
    case "addFileDescription":
      let index = state.fileDescription.findIndex(
        (file) => file.uid === action.value.uid
      );
      let newFileDescription = state.fileDescription;
      if (index !== 1) {
        newFileDescription[index] = action.value;
        return {
          ...state,
          fileDescription: newFileDescription,
        };
      }
      return { ...state };
    case "addFile":
      if (
        !state.fileDescription.find((item) => item.uid === action.value.uid)
      ) {
        const newFileDescription = state.fileDescription.concat(action.value);
        newFileDescription.sort((a, b) => {
          return a.uid - b.uid;
        });
        return {
          ...state,
          fileDescription: newFileDescription,
        };
      }
      return { ...state };
    case "editFileUploadProgress":
      let i = state.fileDescription.findIndex(
        (file) => file.uid === action.key
      );
      let newFilePercent = state.fileDescription;
      if (i !== 1) {
        newFilePercent[i].percent = action.value;
        return {
          ...state,
          fileDescription: newFilePercent,
        };
      }
      return { ...state };

    case "FileUploadSuccess":
      let j = state.fileDescription.findIndex(
        (file) => file.uid === action.value.uid
      );
      if (j !== -1) {
        const updatedFile = action.value;
        const newFileDescription = [
          ...state.fileDescription.slice(0, j),
          updatedFile,
          ...state.fileDescription.slice(j + 1),
        ];
        return {
          ...state,
          fileDescription: newFileDescription,
        };
      }
      return { ...state };
    case "FileUploadError":
      let k = state.fileDescription.findIndex(
        (file) => file.uid === action.value.uid
      );
      if (k !== -1) {
        const updatedFile = action.value;
        const newFileDescription = [
          ...state.fileDescription.slice(0, k),
          updatedFile,
          ...state.fileDescription.slice(k + 1),
        ];
        return {
          ...state,
          fileDescription: newFileDescription,
        };
      }
      return { ...state };
    case "removeFile":
      return {
        ...state,
        fileDescription: state.fileDescription.filter(
          (item) => item.uid !== action.value
        ),
      };
    case "modifyRequestName":
      return {
        ...state,
        requestName: action.value,
      };
    case "modifyRequestDescription":
      return {
        ...state,
        requestDescription: action.value,
      };
    default:
      throw new Error();
  }
}

export default function RequestCreationReducer(props) {
  const initialState = {
    requestName: null,
    requestDescription: "",
    workStreams: "None",
    constructionMethodology: [],
    methodologyBuilding: [],
    methodologyPhase: "None",
    methodologyOutputs: [],
    modelDevelopment: [],
    modelPhase: "None",
    modelEquipment: [],
    modelType: [],
    modelUsage: [],
    fileDescription: [],
  };

  const [buttonState, dispatch] = useReducer(reducer, initialState);

  return (
    <ButtonStateContext.Provider value={{ buttonState, dispatch }}>
      {props.children}
    </ButtonStateContext.Provider>
  );
}
